import * as mutations from './mutations'
import * as actions from './actions'
import * as getters from './getters'
import 'url-search-params-polyfill'
import { MODAL_STATES, LOAD, APP_STATES, PATHS } from 'src/config/app'

// URL PARAMS
let urlParams = new window.URLSearchParams(window.location.search)

// DEBUG
let debug = false // process.env.NODE_ENV !== 'production'
if (urlParams.get('_debug') !== null) debug = true
if (urlParams.get('_debug') === 'false') debug = false

// DEBUG AO
let debugAo = false // process.env.NODE_ENV !== 'production'
if (urlParams.get('_debugAo') !== null) debugAo = true
if (urlParams.get('_debugAo') === 'false') debugAo = false

// DEBUG TEXTURES
let debugTextures = false // process.env.NODE_ENV !== 'production'
if (urlParams.get('_debugTextures') !== null) debugTextures = true
if (urlParams.get('_debugTextures') === 'false') debugTextures = false

// CONTROLS
let controls = process.env.NODE_ENV !== 'production'
controls = false
if (urlParams.get('_controls') !== null) controls = true
if (urlParams.get('_controls') === 'false') controls = false

// DAT GUI
let USE_DATGUI = false
if (urlParams.get('gui') !== null) USE_DATGUI = true
if (urlParams.get('gui') === 'false') USE_DATGUI = false
if (urlParams.get('gui') === 'true') USE_DATGUI = true

// SCROLL
let scroll = false
// if (urlParams.get('scroll') !== null) scroll = true
// if (urlParams.get('scroll') === 'false') scroll = false

// STATE
const state = {
  debug,
  debugAo,
  debugTextures,

  controls,
  scroll,
  USE_DATGUI,

  // Version
  version: {
    build: 1000,
    date: null,
  },

  // LOADING
  loading: {
    state: LOAD.STATES.FRAMEWORK_LOADING,
    steps: {
      [LOAD.GROUPS.CRITICAL]: false,
      [LOAD.GROUPS.MAIN]: false,
    },
  },

  app_state: APP_STATES.LOADING,
  prev_app_state: APP_STATES.LOADING,

  // ROUTE
  route: {
    // default page to show after loading
    afterLoad: {
      path: `${PATHS.BASE}${PATHS.HOME}`,
      name: PATHS.HOME,
      hash: '',
    },
    curr: {
      parent: null,
      child: null,
    },
    prev: {
      parent: null,
      child: null,
    },
  },

  prev_modal_state: MODAL_STATES.LOADING,
  modal_state: MODAL_STATES.LOADING,

  motion_clamp: 1,
}

// EXPORT
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
