import LogLevel from 'loglevelnext';

const consoleLog = LogLevel;

consoleLog.log = consoleLog.debug;

const DEBUG = process.env.VUE_APP_DEBUG === "on";
consoleLog.level = DEBUG ? consoleLog.levels.DEBUG : consoleLog.levels.WARN;


export default consoleLog;