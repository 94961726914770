import { clamp } from 'src/helpers/MathUtils'
import EventEmitter from 'src/helpers/EventEmitter'
import EventObserver from 'src/helpers/EventObserver'
import env from 'src/config/env'

export const REM_SCALE_MODES = {
  FIT: 'fit',
}

export const REM_SCALE_UNITS = {
  WINDOW: 'WINDOW',
  PERCENT: 'PERCENT',
  VIEWPORT: 'VIEWPORT',
}

export const EVENTS = {
  RESIZE: 'resize',
}

export const DEVICE_MOBILE = 'mobile'
export const DEVICE_TABLET = 'tablet'
export const DEVICE_DESKTOP = 'desktop'

export const BREAKPOINT_MOBILE = 'breakpointMobile'
export const BREAKPOINT_MOBILE_LANDSCAPE = 'breakpointMobileLandscape'
export const BREAKPOINT_TABLET = 'breakpointTablet'
export const BREAKPOINT_DESKTOP = 'breakpointDesktop'

let defaultConfig = {
  alwaysEmitResize: env.ios && env.facebook_video_ad, // always trigger resize event even if dimensions haven't changed
  remScaleMode: REM_SCALE_MODES.FIT,
  remScaleUnit: REM_SCALE_UNITS.WINDOW,
  scale: 1,
  baseFontSize: 10,

  breakpoints: {
    [BREAKPOINT_MOBILE]: {
      name: BREAKPOINT_MOBILE,
      maxWidth: 550,
    },

    [BREAKPOINT_TABLET]: {
      name: BREAKPOINT_TABLET,
      maxWidth: 1280,
    },

    [BREAKPOINT_DESKTOP]: {
      name: BREAKPOINT_DESKTOP,
      maxWidth: 20000,
    },
  },

  deviceTypes: {
    [DEVICE_MOBILE]: {
      //iphone SE for this project
      designSize: {
        width: 320, //375 (note : 375 value multiplier = 0.85333 )
        height: 568, //667
      },
      remScale: {
        min: 1.0,
        max: 1.3,
        widthScaleOnly: true,
      },
    },

    [DEVICE_TABLET]: {
      designSize: {
        width: 1440,
        height: 800,
      },
      remScale: {
        min: 0.8,
        max: 1.0,
        widthScaleOnly: false,
      },
    },

    [DEVICE_DESKTOP]: {
      designSize: {
        width: 1440,
        height: 800,
      },
      remScale: {
        min: 0.8,
        max: 1.0,
        widthScaleOnly: false,
      },
    },
  },
}

class ResizeService {
  static get EVENTS() {
    return EVENTS
  }
  get Events() {
    return EVENTS
  }

  constructor(config) {
    Object.assign(this, EventEmitter)
    Object.assign(this, EventObserver)

    this.prevOrientation = null

    this.config = Object.assign({}, defaultConfig, config)

    //this._makeRulers()

    this.html = document.querySelector('html')

    this.env = env
    //add env browser classes to html.
    for (let n in this.env) {
      if (this.env[n]) {
        this.html.classList.add(n)
      }
    }


    this._scale = 1
    this._resize(true)
    //this._updateCssVarHeight(this.height)
    setTimeout(() => {
      this._resize(true)
      //this._updateCssVarHeight(this.height)
    }, 1000)

    this.prevHeight = 0
    this._setScreenHeightCssVar()

    this.onFrame()
  }

  destroy() {
    this.unlisten()
  }

  get wh() {
    // if ((this.isMobile || this.isTablet) && !!window.visualViewport) {
    //   return window.visualViewport.height
    // }
    return window.innerHeight
  }
  get ww() {
    // if ((this.isMobile || this.isTablet) && !!window.visualViewport) {
    //   return window.visualViewport.width
    // }
    return window.innerWidth
  }

  get vh() {
    return this.wh //this.el_vh.clientHeight /*css 100vh*/
  }
  get vw() {
    return this.ww /*css 100vw*/
  }

  get perc_h() {
    return this.wh //this.el_perc.clientHeight /*css 100%*/
  }
  get perc_w() {
    return this.ww /*css 100%*/
  }

  get isMobile() {
    return env.mobile
  }
  get isTablet() {
    return env.tablet
  }
  get isDesktop() {
    return env.desktop
  }

  get scale() {
    return this._scale
  }
  set scale(val) {
    this._scale = val
    this.fontSize = this.config.baseFontSize * this._scale
    this.html.style.fontSize = `${this.fontSize}px`
  }

  get breakpoint() {
    let bps = this.config.breakpoints
    for (let b in bps) {
      if (this.width <= bps[b].maxWidth) {
        return b
      }
    }
    return BREAKPOINT_DESKTOP
  }

  get landscape() {
    return this.ww > this.wh
  }

  get orientation() {
    return this.landscape ? 'landscape' : 'portrait'
  }

  //deprecated
  get device() {
    return this.deviceType
  }

  get deviceType() {
    let dt = DEVICE_DESKTOP
    if (env.tablet) {
      dt = DEVICE_TABLET
    }
    if (env.mobile || env.tablet) {
      //all other mobile devices
      dt = DEVICE_MOBILE
    }
    return dt
  }

  //reconfigure

  get breakpoints() {
    return this.config.breakpoints
  }
  set breakpoints(config) {
    this.config.breakpoints = config
    this._resize()
  }

  get devices() {
    return this.config.devices
  }
  set devices(config) {
    this.config.devices = config
    this._resize()
  }

  get baseFontSize() {
    return this.config.baseFontSize
  }
  set baseFontSize(val) {
    this.config.baseFontSize = val
    this._resize()
  }

  onFrame = () => {
    this._resize()
    window.requestAnimationFrame(this.onFrame)
  }

  ///INTERNAL UTIL:

  _resize = (forceEmit = false) => {
    let w = this.ww
    let h = this.wh

    if (!!forceEmit) {
      //console.log('resize forced')
    }

    let changed = w !== this.width || h !== this.height
    this.width = w
    this.height = h

    let wasInMobileLandscape = !env.desktop && this.orientation === 'portrait' && this.prevOrientation === 'landscape'

    if (wasInMobileLandscape) {
      //console.log('resize orientation changed to', this.orientation)
      clearTimeout(this.resizeID)
      this.resizeID = setTimeout(() => {
        this._resize(true)
      }, 1000)
    }

    if (this.prevOrientation !== this.orientation) {
      //console.log('resize orientation', this.orientation)
    }

    this.prevOrientation = this.orientation

    let isMobilePortrait = (env.mobile || env.tablet) && this.orientation === 'portrait'
    let trigger = (changed && (!isMobilePortrait || wasInMobileLandscape)) || this.config.alwaysEmitResize || forceEmit

    let cssHeightChanged = false
    if (changed) {
      this._updateFontSize()
      this._updateBreakpointClass()
      let prevCssHeight = this.cssVarViewportHeight
      this._setScreenHeightCssVar()
      cssHeightChanged = prevCssHeight !== this.cssVarViewportHeight
    }

    let updateCSSHeightMobile = isMobilePortrait && cssHeightChanged
    if (updateCSSHeightMobile) {
      //console.log('resize updateCSSHeightMobile')
    }

    if (trigger || updateCSSHeightMobile) {
      this.emit(EVENTS.RESIZE, {
        width: this.width,
        height: this.height,
        breakpoint: this.breakpoint,
        deviceType: this.deviceType,
        orientation: this.orientation,
        scale: this.scale,
      })
      //console.log('RE - S - I - Z - E')
    }
  }

  // _updateCssVarHeight = h => {
  //   //todo add logic to prevent update on mobile scroll
  //   //note: (h - 1) avoiding rounding issue -flickering scrollbar
  //   let root = window.document.documentElement
  //   root.style.setProperty('--visible-height', h + 'px')
  // }

  _setScreenHeightCssVar = () => {
    // set once - only if height is greater than width
    // if (!this.screenHeightCssVarSet) {
    if (!env.desktop && this.height < this.width) {
      return
    }

    //let mbHeight = window.visualViewport ? window.visualViewport.height : Math.max(window.screen.height, window.screen.width)
    let mbHeight = Math.max(window.screen.height, window.screen.width)

    let outerHeight = env.desktop ? this.wh : mbHeight
    //let innerHeight = Math.max(window.innerHeight, window.innerWidth)
    let tgHeight = outerHeight
    if (tgHeight > this.prevHeight || env.desktop) {
      this.prevHeight = tgHeight
      this.cssVarViewportHeight = this.prevHeight
      // console.log(
      //   'resize this.cssVarViewportHeight:',
      //   this.cssVarViewportHeight,
      //   'sh:',
      //   window.screen.height,
      //   'wh:',
      //   window.innerHeight
      //   //'wvvh:',
      //   //window.visualViewport.height
      // )
      document.documentElement.style.setProperty('--viewport-height', this.cssVarViewportHeight + 'px')
    }
    //this.screenHeightCssVarSet = true
    // }
  }

  _getRemScaleHeight = () => {
    if (this.config.remScaleUnit === REM_SCALE_UNITS.VIEWPORT) {
      return this.vh
    }
    if (this.config.remScaleUnit === REM_SCALE_UNITS.PERCENT) {
      return this.perc_h
    }
    return this.wh
  }

  _getRemScaleWidth = () => {
    if (this.config.remScaleUnit === REM_SCALE_UNITS.VIEWPORT) {
      return this.vw
    }
    if (this.config.remScaleUnit === REM_SCALE_UNITS.PERCENT) {
      return this.perc_w
    }
    return this.ww
  }

  _updateFontSize = () => {
    let ww = this._getRemScaleWidth()
    let wh = this._getRemScaleHeight()

    let d = this.config.deviceTypes[this.deviceType]
    if (d[this.orientation]) {
      d = d[this.orientation]
    } // can have separate portrait/landscape logic
    let scaleX = ww / d.designSize.width
    let scaleY = wh / d.designSize.height

    let scale = d.remScale.widthScaleOnly ? scaleX : Math.min(scaleX, scaleY)
    this.scale = clamp(scale, d.remScale.min, d.remScale.max)
  }

  _updateBreakpointClass = () => {
    let breakpoint = this.breakpoint
    let bps = this.config.breakpoints
    for (let b in bps) {
      this.html.classList[b === breakpoint ? 'add' : 'remove'](b)
    }
  }

  // _makeRulers() {
  //   this.el_vh = document.createElement('div')
  //   this.el_vh.style.cssText = `position: absolute;
  //                             pointer-events:none;
  //                             top:0px;
  //                             width:1px;
  //                             height:100vh;
  //                             `
  //   this.el_perc = document.createElement('div')
  //   this.el_perc.style.cssText = `position: absolute;
  //                               pointer-events:none;
  //                               top:0px;
  //                               width:1px;
  //                               height:100%;
  //                               `
  //
  //   document.body.appendChild(this.el_vh)
  //   document.body.appendChild(this.el_perc)
  // }
}

let service = new ResizeService()
window.ResizeService = service
export default service
