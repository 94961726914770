import { clamp } from '@/helpers/MathUtils'
import Device from '@/libs/device'

const ENV_TARGET = {
  LOCAL: 'local',
  STAGING: 'staging',
  PRODUCTION: 'prod',
  DEV: 'dev',
}

let env = ENV_TARGET.DEV
if (window.location.hostname.match(/(localhost|192\.168\.)/i)) {
  env = ENV_TARGET.LOCAL
  //} else if (window.location.hostname.match(/(^dev\.|\.resn\.global$|\.resn\.co$)/i)) {
  // } else if (window.location.hostname.match(/(\.resn\.co$|localhost|^dev\.|192\.168\.)/i)) {
  //  env = ENV_TARGET.DEV
} else if (window.location.hostname.includes('staging.goodmeat.co')) {
  env = ENV_TARGET.STAGING
} else if (window.location.hostname.includes('goodmeat.co')) {
  env = ENV_TARGET.PRODUCTION
}

let isDev = [ENV_TARGET.LOCAL, ENV_TARGET.DEV].includes(env)
let isDevOrStage = [ENV_TARGET.LOCAL, ENV_TARGET.DEV, ENV_TARGET.STAGING].includes(env)
let isProduction = [ENV_TARGET.PRODUCTION].includes(env)
let isStaging = [ENV_TARGET.STAGING].includes(env)

let ua = window.navigator.userAgent
let ua_lc = ua.toLowerCase()
let href = window.location.href
let vendor = navigator.vendor

let edge = /edge\/\d+/.test(ua)
let ie11 = ua_lc.indexOf('trident') != -1 && ua.indexOf('rv:11') != -1
let ie = window.document.documentMode

let windows = /windows/.test(ua_lc)
let mac = /Apple Computer/.test(vendor)

//////let mobile =  /(iphone|ipad|ipod|android)/.test(ua_lc) //deprecated
let mobile_ua = /mobile/.test(ua_lc)
let ios = /(ios|ipad|iphone|ipod)/.test(ua_lc)
let android = /android/.test(ua_lc)

//PHONE
let windows_phone = windows && mobile_ua
let android_phone = android && mobile_ua
let iphone = /iphone/.test(ua_lc)
let ipod = /ipod/.test(ua_lc)

let phone = windows_phone || android_phone || iphone || ipod
let mobile = phone

//TABLET
let android_tablet = android && !mobile_ua
let ios_desktop_tablet_touch = function () {
  const ua = window.navigator.userAgent

  if (ua.indexOf('Macintosh') > -1) {
    try {
      document.createEvent('TouchEvent')
      return true
    } catch (e) {
      return false
    }
  }

  return false
}
let ipad = ios_desktop_tablet_touch() && !(iphone && ipod)

let windows_tablet = windows && /touch/.test(ua_lc) && !windows_phone

let tablet = android_tablet || ipad || windows_tablet

//DESKTOP
let desktop = !tablet && !phone

// PIXEL
// let pixelRatioMin = 1.4
// let pixelRatioMax = mobile ? 2 : 1.5
// let pixelRatio = tablet ? 1 : clamp(window.devicePixelRatio, pixelRatioMin, pixelRatioMax)

let pixelRatio = tablet ? 1.0 : 1.5
// if (mobile) {
//   pixelRatio = 1.5; // clamp(window.devicePixelRatio, 1, 1.5)
// } else if (tablet) {
//   pixelRatio = 1; // clamp(window.devicePixelRatio, 1, 1.5)
// } else {
//   pixelRatio = 1.5; // clamp(window.devicePixelRatio, 1, 1.5)
// }

// TOUCH
function is_touch_device() {
  try {
    document.createEvent('TouchEvent')
    return true
  } catch (e) {
    return false
  }
}
let touch_device = is_touch_device()

let desktop_safari = !ios && /safari/.test(ua_lc) && mac

let messenger = /fban\/messengerforios/.test(ua_lc) || /fb_iab\/messenger/.test(ua_lc)

var facebook_video_ad_urlparam = window.location.href.indexOf('fbvideo') > -1
let el_vh = document.createElement('div')
el_vh.style.cssText = `position: absolute;
                      pointer-events:none;
                      top:0px;
                      width:1px;
                      height:100vh;`
document.body.appendChild(el_vh)
let vh = el_vh.clientHeight
let facebook_video_ad = ios
  ? facebook_video_ad_urlparam && window.screen.height - vh < 30
  : facebook_video_ad_urlparam && window.screen.height - window.innerHeight < 30
document.body.removeChild(el_vh)

let facebook = /(fban|fbav)/.test(ua_lc) && !messenger && !facebook_video_ad

let instagram = /instagram/.test(ua_lc)
let wechat = /micromessenger/.test(ua_lc)
let weibo = /weibo/.test(ua_lc)
let ucbrowser = /ucbrowser/.test(ua_lc)
let samsung = /samsung/.test(ua_lc)

let other_ios_browsers = /(crios|fxios|opios|mercury|ucbrowser|fbav|fban|instagram)/.test(ua_lc)
let other_android_browsers = /(opera|ucbrowser|samsung|fbav|fban|instagram)/.test(ua_lc)

let ios_safari = ios && !other_ios_browsers
let ios_chrome = ios && /crios/.test(ua_lc)

let android_chrome = android && !other_android_browsers && /chrome/.test(ua_lc) && !messenger

const prerenderHTML = window.__PRERENDER_INJECTED && window.__PRERENDER_INJECTED.rendering

export default {
  env,
  ENV_TARGET,

  isDev,
  isDevOrStage,
  isProduction,
  isStaging,

  pixelRatio,

  touch_device,

  mobile,
  phone,

  tablet,
  desktop,

  ios,
  android,

  edge,
  ie11,
  ie,

  desktop_safari,

  facebook_video_ad,
  facebook,
  messenger,
  instagram,

  wechat,
  weibo,
  ucbrowser,
  samsung,

  ios_safari,
  ios_chrome,

  android_chrome,

  windows,
  mac,

  windows_phone,
  android_phone,
  iphone,
  ipod,

  android_tablet,
  ipad,
  windows_tablet,

  prerenderHTML,
}
