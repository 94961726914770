import console from '@/services/ConsoleService'

export const setVersion = (state, data) => {
  state.version.build = data.build
  state.version.date = data.date
}

// **************************************************
//
// LOADING
//
// **************************************************
export const setLoadProgress = (state, progress) => {
  state.loading.progress = Math.min(progress, 1)
}
export const setLoadStep = (state, step) => {
  state.loading.step = step
  console.log('setLoadStep', step)
}
export const setLoadComplete = (state) => {
  state.loading.progress = 1
  state.loading.completed = true
}

// **************************************************
//
// ROUTE
//
// **************************************************
export const setRoute = (state, params) => {
  state.route.prev.parent = state.route.curr.parent
  state.route.prev.child = state.route.curr.child

  state.route.curr.parent = params.parent
  state.route.curr.child = params.child
}

export const setRouteAfterLoad = (state, value) => {
  console.log('setafterload', value)
  state.route.afterLoad = value
}
