// **************************************************
// LOAD SERVICE
// **************************************************
//
// Sample Manifest
//
// [
//   {
//     id: 'scene1',
//     urls: [
//       require('src/assets/videos/portraitofher.mp4')
//     ]
//   },
//   {
//     id: 'scene2',
//     urls: [
//       require('src/assets/demo/sequence/000.jpg'),
//       require('src/assets/demo/sequence/001.jpg'),
//       require('src/assets/demo/sequence/002.jpg'),
//     ]
//   }
// ]
//
// **************************************************

import EventEmitter from 'src/helpers/EventEmitter'
import Queue from 'src/helpers/Queue'
import console from '@/services/ConsoleService'
// import PQueue from 'p-queue'

const Events = {
  GROUP_PROGRESS: 'groupProgress',
  GROUP_COMPLETED: 'groupCompleted',
  PROGRESS: 'progress',
  COMPLETED: 'completed',
}

class Group {
  constructor(id) {
    Object.assign(this, {
      id: id,
      assets: [],
      progress: 0, // 0 to 1
      completed: false,
    })
  }
}

class Asset {
  constructor(url, groupId) {
    Object.assign(this, {
      url: url,
      groupId: groupId,
      bytesLoaded: 0,
      bytesTotal: 1,
      progress: 0, // 0 to 1
      completed: false,
      callback: null,
    })
  }
}

class AssetService {
  constructor() {
    Object.assign(this, EventEmitter)
    this.concurrency = 2

    // PROGRESS
    this._progress = 0
    this._completed = false

    // GROUPS AND ASSETS
    this.groups = []
    this.assets = []
  }

  _onProgress = data => {
    // console.log('_onProgress', data.url)

    let asset = this.assets.filter(asset => {
      return asset.url === data.url
    })[0]

    asset.bytesTotal = data.total || 1
    asset.bytesLoaded = data.loaded || 0
    asset.progress = asset.bytesLoaded / asset.bytesTotal

    this._updateGroupProgress()
    this._updateTotalProgress()
  }

  _onLoad = data => {
    // console.log('_onLoad', data.url)

    let asset = this.assets.filter(asset => {
      return asset.url === data.url
    })[0]

    if (window.URL) asset.blobUrl = URL.createObjectURL(data.xhr.response)
    asset.bytesTotal = data.total || 1
    asset.bytesLoaded = asset.bytesTotal
    asset.progress = 1
    asset.completed = true

    this._updateGroupProgress()
    this._updateTotalProgress()

    if (typeof asset.callback === 'function') asset.callback()
  }

  _updateGroupProgress = () => {
    for (const group of this.groups) {
      let progress = 0
      for (const asset of group.assets) {
        progress += asset.progress
      }
      progress /= group.assetsTotal

      if (group.progress !== progress && !group.completed) {
        this.emit(Events.GROUP_PROGRESS, {
          groupId: group.id,
          progress: progress,
        })
        if (progress >= 1 && !group.completed) {
          //console.log(`ASSET LOAD GROUP COMPLETED ${group.id}`)
          group.completed = true
          this.emit(Events.GROUP_COMPLETED, { groupId: group.id })
        }
      }
      group.progress = Math.min(progress, 1)
    }
  }

  _updateTotalProgress = () => {
    let progress = 0
    for (const asset of this.assets) {
      progress += asset.progress
    }
    progress /= this.assets.length
    if (this.assets.length === 0) progress = 1

    if (this._progress !== progress) {
      this.emit(Events.PROGRESS, progress)
      if (progress >= 1) {
        this._completed = true
        this.emit(Events.COMPLETED)
      }
    }
    this._progress = Math.min(progress, 1)
  }

  _loadAsset = asset => {
    return new Promise(resolve => {
      let xhr = new XMLHttpRequest()
      xhr.crossOrigin = 'anonymous'
      xhr.responseType = 'blob'

      xhr.onprogress = evt => {
        this._onProgress({
          url: asset.url,
          loaded: evt.loaded,
          total: evt.total,
        })
      }

      xhr.onload = () => {
        this._onLoad({
          url: asset.url,
          xhr: xhr,
        })
        resolve()
      }

      xhr.open('GET', asset.url)
      xhr.send()
    })
  }

  _loadAssets = assets => {
    return new Promise(resolve => {
      // let q = new PQueue({ concurrency: this.concurrency })
      let q = new Queue(this.concurrency)
      for (let asset of assets) {
        q.add(() => {
          return this._loadAsset(asset)
        })
      }
      // q.next()
      if (q.pending === 0) resolve()
      // q.onIdle().then(resolve)
      q.on(Queue.Events.EMPTY, () => {
        resolve()
      })
    })
  }

  load = (manifest, concurrency = 2, groupId = '') => {
    this.concurrency = concurrency

    for (const config of manifest) {
      if (config.id !== groupId && groupId !== '') continue
      //console.log(`ASSET LOAD GROUP ${config.id}`)

      // check if group exists
      let group = this.groups.filter(group => {
        return group.id === groupId
      })[0]

      // if not, add it
      if (!group) {
        // console.log('make group', config.id)
        group = new Group(config.id)
        this.groups.push(group)
      }

      for (let url of config.urls) {
        // Check if exists
        let asset = this.assets.filter(asset => {
          return asset.url === url
        })[0]
        if (asset) continue

        // Create asset
        asset = new Asset(url, group.id)
        this.assets.push(asset)
        group.assets.push(asset)
      }

      group.assetsTotal = group.assets.length
    }
    // console.log(this.groups, this.assets)

    // LOAD
    return new Promise(resolve => {
      // let q = new PQueue({ concurrency: 1 }) // Load only 1 group at a time
      let q = new Queue(1) // Load only 1 group at a time

      let assets = this.assets.filter(asset => {
        return asset.groupId === groupId
      })
      if (assets.length > 0) {
        q.add(() => {
          return this._loadAssets(assets)
        })
      }

      let onComplete = () => {
        this._updateGroupProgress()
        this._updateTotalProgress()
        console.log(`AssetService loaded`)
        resolve()
      }

      // q.next()
      if (q.pending === 0) onComplete()
      // q.onIdle().then(onComplete)
      q.on(Queue.Events.EMPTY, onComplete)
    })
  }

  getAsset = url => {
    let asset = this.assets.filter(asset => {
      return asset.url === url
    })[0]
    return asset
  }

  getGroupById = (manifest, id) => {
    for (const config of manifest) {
      if (config.id === id) return config
    }
    return false
  }
}

let service = new AssetService()
service.Events = Events
window.AssetService = service
export default service
