import console from '@/services/ConsoleService'

class FontServiceFontFace {
  load = async (manifest) => {
    console.log('FontServiceFontFace.load', manifest)

    for (let item of manifest) {
      let url = `url(${item.src})`
      let font = new FontFace(item.name, url, item.options)
      await font.load()
      document.fonts.add(font)
    }

    await document.fonts.ready
  }
}

let service = new FontServiceFontFace()
export default service
