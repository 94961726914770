export const PATHS = {
  BASE: '/',
  LOADING: 'loading',
  HOME: 'home',
  SIGNUP: 'signup',
}

export const APP_STATES = {
  // loading states
  LOADING: 'loading',
  HOME: 'home',
  SIGNUP: 'signup',
}


export const MODAL_STATES = {
  NONE: 'MODAL_STATE:NONE',
  // MENU: 'MODAL_STATE:MENU',
  LOADING: 'MODAL_STATE:LOADING',
}

export const LOAD = {
  GROUPS: {
    CRITICAL: 'LOAD:GROUPS:CRITICAL',
    MAIN: 'LOAD:GROUPS:MAIN'
  },
  STATES: {
    // FRAMEWORK - Bootstrap app
    FRAMEWORK_LOADING: 'LOAD:STATES:FRAMEWORK_LOADING',
    FRAMEWORK_LOADED: 'LOAD:STATES:FRAMEWORK_LOADED',

    // CRITICAL - Initial Assets needed first fold / loading et al
    CRITICAL_LOADING: 'LOAD:STATES:CRITICAL_LOADING',
    CRITICAL_LOADED: 'LOAD:STATES:CRITICAL_LOADED',

    // MAIN - Sundry Assets
    MAIN_LOADING: 'LOAD:STATES:MAIN_LOADING',
    MAIN_LOADED: 'LOAD:STATES:MAIN_LOADED',

    // LOAD COMPLETE
    COMPLETE: 'LOAD:STATES:COMPLETE',
  },
}

export const EVENTS = {
  RESIZE: 'EVENTS:RESIZE'
}
