// ********************************************************
//
// IMPORT
//
// ********************************************************
import FontService from '@/services/FontService'
import AssetService from '@/services/AssetService'
// import MediaService from '@/services/MediaService'
// import SoundService from '@/services/SoundService'
import '@/services/ResizeService'
import '@/services/AnalyticsService'
import Raf from '@/helpers/Raf'
import console from '@/services/ConsoleService'

import fontManifest from '@/config/fonts'
import assetManifest from '@/config/asset'
// import mediaManifest from '@/config/media'
// import soundManifest from '@/config/sound'

import store from '@/store'

// ********************************************************
//
// DOCUMENT
//
// ********************************************************
const loadDocument = function() {
  return new Promise((resolve) => {
    const onReady = function() {
      setTimeout(resolve, 1)
    }

    if (document.readyState === 'complete') {
      onReady()
    } else {
      document.onreadystatechange = () => {
        if (document.readyState === 'complete') {
          onReady()
        }
      }
    }
  })
}

// ********************************************************
//
// APP
//
// ********************************************************
async function initApp() {
  await loadDocument()

  console.log('Initialization')

  // store.commit('app/setLoadStep', 'raf')
  // Raf.start()

  store.commit('app/setLoadStep', 'app')
  await import('./main')
  // store.commit('app/setLoadState', LOAD.STATES.FRAMEWORK_LOADED)

  // store.commit('app/setLoadStep', 'fonts')
  // await FontService.load(fontManifest)

  // store.commit('app/setLoadStep', 'media')
  // await MediaService.load(mediaManifest)

  // store.commit('app/setLoadStep', 'sound')
  // await SoundService.load(soundManifest)

  store.commit('app/setLoadStep', 'assets')
  await AssetService.load(assetManifest, 2, 'scene1')

  store.commit('app/setLoadStep', 'completed')
  store.commit('app/setLoadComplete')

  // COMPLETE
  // store.commit('app/setLoadState', LOAD.STATES.COMPLETE)
  store.dispatch('app/getVersion')
}

// ********************************************************
//
// INIT
//
// ********************************************************
initApp()
