import FontFaceObserver from 'fontfaceobserver'

class FontService {
  load = (manifest) => {
    let p = []
    for (let item of manifest) {
      let font = new FontFaceObserver(item.name, item.options)
      p.push(font.load())
    }
    return Promise.all(p)
  }
}

let service = new FontService()
export default service
