import env from 'src/config/env.js'
import { APP_STATES, MODAL_STATES } from 'src/config/app'

const trackingId_local = 'G-R3PKPYF9CG' //resn_has_no_i
//const trackingId_live = 'G-KQF5CSN6TS' // goodmeat.co
//const trackingId_staging = 'G-42P2WP8NJX' // staging.goodmeat.co

export const debug = true
export const active = true

// let trackingIdMap = {
//   [env.ENV_TARGET.PRODUCTION]: trackingId_live,
//   [env.ENV_TARGET.STAGING]: trackingId_staging,
//   [env.ENV_TARGET.DEV]: trackingId_local,
//   [env.ENV_TARGET.LOCAL]: trackingId_local,
// }

//let myvar = process.env.VUE_APP_GA_CODE

export let trackingId = process.env.VUE_APP_GA_CODE || trackingId_local //trackingIdMap[env.env] || trackingId_local //resn

export let GA_EVENT = {
  LOGO_CLICK: { category: 'header', action: 'click', label: 'logo-home', value: null },

  IOS_CLICK: { category: 'delivery', action: 'click', label: 'youtube-ios-download', value: null },
  ANDROID_CLICK: { category: 'delivery', action: 'click', label: 'youtube-android-download', value: null },
  WATCH_CLICK: { category: 'delivery', action: 'click', label: 'watch-video', value: null },
  LEARN_MORE_CLICK: { category: 'delivery', action: 'click', label: 'desktop-learn-more', value: null },

  SIGNUP_SUBMISSION: { category: 'signup', action: 'click', label: 'successful-signup', value: null },
}

Object.keys(GA_EVENT).forEach(k => {
  GA_EVENT[k].id = k
})

/*
eg: AnalyticsService.sendEventObj(GA_EVENT.CLOSE)
AnalyticsService.sendEventObj(Object.assign({},GA_EVENT.CLOSE, {label: `someData${value}`})
*/

export const GA_PAGES = {
  PAGE_FOODPANDA: '/foodpanda',
  PAGE_DELIVERY: '/delivery'
}
