

import { GA_EVENT , GA_PAGES } from 'src/config/analytics'
// let AnalyticsService = import('./src/AnalyticsServiceGa').default
let AnalyticsService = require('./src/AnalyticsServiceGtag').default

export  { GA_EVENT , GA_PAGES }
export default AnalyticsService

window.AnalyticsService = AnalyticsService // DEBUG

AnalyticsService.sendPageview('/loading')
