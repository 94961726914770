import Vue from 'vue'
import Vuex from 'vuex'

import browser from './browser' 
import app from './app'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    browser: browser,
    app: app,
  },
  strict: process.env.NODE_ENV !== 'production',
})

if (module.hot) {
  module.hot.accept(['./browser', './app'], () => {
    store.hotUpdate({
      modules: {
        browser: require('./browser'), 
        app: require('./app'),
      },
    })
  })
}

export default store
